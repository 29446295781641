.CalendarDay__blocked_calendar {
  background-color: #ed2b2f;
  color: white;
}

.CalendarDay__blocked_calendar:hover {
  background-color: #ed2b2f;
  color: white;
  border: none;
  cursor: default !important;
}
