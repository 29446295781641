@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

[class^="ribbon-"] {
    position: relative;
    margin-bottom: 80px;
}

[class^="ribbon-"]:after,
[class^="ribbon-"]:before {
    content: "";
    position: absolute;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    border-width: 0px;
    --tw-border-opacity: 1;
    border-color: rgba(var(--c-neutral-800), var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(21, 109, 161, var(--tw-bg-opacity)); 
    color: rgba(255, 255, 0 var(--tw-text-opacity));
    border-radius: 50px; 
}
.CalendarDay__hovered_span:hover, .CalendarDay__hovered_span, .CalendarDay__selected_span {
     border-width: 0px; 
    --tw-border-opacity: 1;
    border-color: rgba(var(--c-neutral-100), var(--tw-border-opacity));
    --tw-bg-opacity: 1; 
    background-color: rgb(224, 232, 247);
    --tw-text-opacity: 1;
    color: rgb(29 79 159);
    border-radius: 50px;
}
.hide-calendar-icon::-webkit-calendar-picker-indicator {
    display: none;
}
.cale
.ribbon-1 {
    width: 42px;
    height: 27px;
    background: #1976d0;
    top: -7px;
    right: 1.6vw;
    z-index: 2;
    position: absolute;
}

.ribbon-1:before {
    height: 0;
    width: 0;
    border-bottom: 6px solid #041628;
    border-right: 6px solid transparent;
    right: -6px;
}

.ribbon-1:after {
    height: 0;
    width: 0;
    border-left: 21px solid #1976d0;
    border-right: 21px solid #1976d0;
    border-bottom: 5px solid transparent;
    bottom: -5px;
}

.ribbon-nodeposit {
    right: 80px;
    width: 135px;
    padding: 0 7px;
    height: 27px;
    background: #28a745;
    top: -7px;
    z-index: 1;
    position: absolute;
    margin-right: 20px;
}

.ribbon-nodeposit:before {
    height: 0;
    width: 0;
    border-bottom: 6px solid #000101;
    border-right: 6px solid transparent;
    right: -6px;
}

.ribbon-nodeposit:after {
    height: 0;
    width: 0;
    border-left: 67.5px solid #28a745;
    border-right: 67.5px solid #28a745;
    border-bottom: 5px solid transparent;
    bottom: -5px;
    right: 0;
}

.ribbon-campaign {
    right: 80px;
    width: 135px;
    padding: 0 25px;
    height: 27px;
    background: red;
    top: -7px;
    z-index: 1;
    position: absolute;
    margin-right: 20px;
}

.ribbon-campaign:before {
    height: 0;
    width: 0;
    border-bottom: 6px solid #000101;
    border-right: 6px solid transparent;
    right: -6px;
}

.ribbon-campaign:after {
    height: 0;
    width: 0;
    border-left: 67.5px solid red;
    border-right: 67.5px solid red;
    border-bottom: 5px solid transparent;
    bottom: -5px;
    right: 0;
}



.ribbon-nodeposit-label {
    display: table-cell;
    vertical-align: middle;
    width: 100px;
    font-size: 15px;

    color: #fff !important;
    text-align: center;
    cursor: default;
    white-space: nowrap;
}

.mobile-ribbon-1 {
    width: 7vw;
    height: 7.2vw;
    background: #1976d0;
    top: -7px;
    right: 5.6vw;
    z-index: 2;
    position: absolute;
}

.mobile-ribbon-1:before {
    height: 0;
    width: 0;
    border-bottom: 6px solid #041628;
    border-right: 6px solid transparent;
    right: -6px;
}

.mobile-ribbon-1:after {
    height: 0;
    width: 0;
    border-left: 3.5vw solid #1976d0;
    border-right: 3.5vw solid #1976d0;
    border-bottom: 5px solid transparent;
    bottom: -5px;
}

.ribbon-container {
    position: relative;
    height: 100%;
    display: table;
}

.mobile-ribbon-label,
.ribbon-label {
    display: table-cell;
    vertical-align: middle;
    width: 100px;
    padding-top: 0;
    color: #fff !important;
    text-align: center;
    cursor: default;
}

.mobile-ribbon-label {
    font-size: 4vw;
}
a {
    color: inherit;
    text-decoration: none;
}

a:hover {
text-decoration: none;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


.MuiButtonBase-root.MuiAccordionSummary-root {
    min-height: 15px!important;
    border-bottom: 1px solid #dedede;
}

.MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters {
    margin: 8px 0!important;
}


.MuiPaper-root.MuiPaper-elevation.MuiAccordion-root {
    margin: 8px 0!important;
    border-top: 1px solid #dedede;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #156da1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #3b464c;
}