/*.FlightCheckout
{
    padding: 20px 250px 120px 250px;
}*/

.flightcheckout-card
{
    position: relative;
    margin-top: 1.5vw;
    padding: 1.08vw;
    height: auto;
    /*box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.2 );*/
    border: 1px solid #d2d0d0;
    background-color: #fff;
}

.flightcheckout-card-passagierinfo
{
    position: relative;
    margin-top: 25px;
    padding: 18px;
    height: auto;
    /*box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.2 );*/
    border: 1px solid #d2d0d0;
    background-color: #fff;
}

.AddressInformation
{
    position: relative;
    margin-top: 25px;
    padding: 18px;
    height: auto;
    border: 1px solid #d2d0d0;
    background-color: #fff;
}

.flightcheckout-card-contactinformation,
.flightcheckout-card-guidelines
{
    position: relative;
    margin-top: 25px;
    padding: 18px;
    height: auto;
    border: 1px solid #d2d0d0;
    background-color: #fff;
}
.flightcheckout-card-remember-contact
{
    position: relative;
    margin-top: 25px;
    padding: 10px 18px;
    height: auto;
    border: 1px solid #d2d0d0;
    background-color: #fff;
}

.flightcheckout-card-paymentinformation
{
    position: relative;
    margin-top: 25px;
    height: auto;
    border: 1px solid #d2d0d0;
}

.flightcheckout-card-contactinformation-confirmation-information
{
    font-size: 12px;
    position: relative;
    padding: 8px 18px;
    height: auto;
    border-right: 1px solid #d2d0d0;
    border-left: 1px solid #d2d0d0;
    border-bottom: 1px solid #d2d0d0;
    background-color: #eaeaea;
}

.FlightRentalcarDriverInformation
{
    position: relative;
    margin-top: 25px;
    padding: 20px 18px;
    height: auto;
    border: 1px solid #d2d0d0;
    background-color: #fff;
}

.flightcheckout-header-info-departure
{
    /*display: grid;
    padding: 0.78vw;
    grid-template-areas: 'flightcheckout-header-info-airline flightcheckout-header-info-date flightcheckout-header-info-from-to flightcheckout-header-info-further';
    grid-template-columns: 4.2vw 13.2vw 17.61vw auto;*/
}

.flightcheckout-card-passagierinfo .MuiInputLabel-outlined
{
    z-index: 1;
    pointer-events: none;
    font-size: 15px;
}

.mobile-flightcheckout-card-passagierinfo
{
    position: relative;
    margin-top: 25px;
    padding: 18px;
    height: auto;
    border: 1px solid #d2d0d0;
    background-color: #fff;
}

.mobile-flightcheckout-header-info-departure,
.mobile-flightcheckout-header-info-return
{
    display: grid;
    padding: 15px;
    grid-template-rows: auto auto auto;
    border-top: 1px solid #8080803b;
}

.flightcheckout-header-info-return
{
    padding-top: 20px;
    /*display: grid;
    padding: 0.78vw;
    grid-template-areas: 'flightcheckout-header-info-airline flightcheckout-header-info-date flightcheckout-header-info-from-to flightcheckout-header-info-further';
    grid-template-columns: 4.2vw 13.2vw 17.61vw auto;*/
}

.flightcheckout-header-info-airline
{
    grid-area: flightcheckout-header-info-airline;
    /*text-align: right;*/
}

.mobile-flightcheckout-header-info-airline
{

}

.flightcheckout-header-info-date
{
    grid-area: flightcheckout-header-info-date;
    font-size: 19px;
    /*font-weight: 600;*/
    color: grey;
    padding-left: 16px;
}

.mobile-flightcheckout-header-info-date
{
    font-size: 16px;
    font-weight: 600;
    color: grey;
    padding-left: 20px;
}

.flightcheckout-header-info-from-to
{
    grid-area: flightcheckout-header-info-from-to;
    padding-left: 1vw;
    display: flex;
    float: left;
    font-size: 19px;
    font-weight: 400;
}

.mobile-flightcheckout-header-info-from-to
{
    display: flex;
    float: left;
    font-size: 17px;
    font-weight: 500;
    padding-top: 10px;
}

.flightcheckout-header-info-further
{
    display: flex;
    float: left;
    padding-left: 1vw;
    font-size: 17px;
}

.mobile-flightcheckout-header-info-further
{
    display: flex;
    float: left;
    padding-top: 10px;
    font-size: 15px;
}

.flightcheckout-card-totalprice
{
    text-align: right;
    font-size: 1.67vw;
    font-weight: 800;
}

.mobile-flightcheckout-card-totalprice
{
    text-align: right;
    font-size: 24px;
    font-weight: 800;
    padding-right: 20px;
    padding-bottom: 5px;
}

.flightcheckout-card-back-button
{
    border-radius: 0 !important;
    background-color: #1976d0 !important;
    width: 120px !important;
    height: 45px !important;
    font-size: 20px !important;
}

.flightcheckout-card-reservation-button
{
    border-radius: 0 !important;
    background-color: #1976d0 !important;
    width: 170px !important;
    height: 45px !important;
    font-size: 20px !important;
}

.mobile-flightcheckout-card-reservation-button
{
    border-radius: 0 !important;
    background-color: #1976d0 !important;
    width: 210px !important;
    height: 70px !important;
    color: white;
    padding: 8px;
    text-align: center;
    font-size: 18px !important;
    font-weight: 800;
}

.mobile-flightcheckout-card-container
{
    background-color: #fff;
    border: 1px solid #d2d0d0;
}

#root #calendar-adt-1 .react-google-flight-datepicker .date-picker.my-own-class-name,
#root .calendar-passenger .react-google-flight-datepicker .date-picker.my-own-class-name
{
    display: none;
}

.mobile-dialog-calendar
{
    position: absolute;
    right: 20px;
    bottom: 25px;
    background-color: #1976d0;
    color: white;
    width: 160px;
    text-align: center;
    height: 50px;
}

.mobile-dialog-calendar-button
{
    padding: 11px;
    font-size: 18px;
    font-weight: 600;
}

.addinsurance-container
{
    padding: 10px;
    border: 1px solid #dbdbdb;
    width: 100%;
    min-height: 160px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.addinsurance-insolvency-container
{
    padding: 10px;
    border: 1px solid #dbdbdb;
    width: 100%;
    min-height: 50px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.addrentalcartoflight-container-mobile
{
    display: grid;
    grid-template-rows: auto auto auto;
    padding: 5px;
    margin: 10px 0px;
    border: 1px solid #dbdbdb;
}
.addrentalcartoflight-secondline-mobile
{
    display: grid;
    grid-template-columns: 40% 60%;
}

.addrentalcartoflight-container
{
    display: grid;
    grid-template-rows: 4vw auto 4.25vw;
    margin: 0.5vw;
    padding: 0.5vw;
    border: 1px solid #dbdbdb;
    max-width: 22vw;
    width: 22vw;
    cursor: pointer;
}
.addrentalcartoflight-container:hover
{
    border: 1px solid #5a5a5a;
}
.addrentalcartoflight-firstline
{
    height: 55px;
}
.addrentalcartoflight-secondline
{
    display: grid;
    grid-template-columns: 40% 60%;
    font-size: 18px;
}
.addrentalcartoflight-thirdline
{
    display: grid;
    grid-template-columns: auto auto;
    border-top: 1px solid #dbdbdb;
}

.addinsurance-thirdline
{
    padding-top: 7px;
}

.addinsurance-insolvency-firstline
{
    font-weight: 700;
    font-size: 18px;
    padding-right: 20px;
}

.addinsurance-insolvency-thirdline
{

}

.loadingOverlay
{
    background-color: black;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    opacity: 0.6;
}
