.react-calendar {
    width: 300px;
    right: 0;
    background: white;
    border: 0px solid #fff;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    z-index: 9999;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}
div[id^="calendar-"] .react-calendar,
.calendar-passenger .react-calendar
{
    width: 300px;
    right: 0;
    background: white;
    border: 0px solid #fff;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    z-index: 9999;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}
.mobile-calendar-passenger .react-calendar
{
    position: inherit;
    left: 0;
    width: 100%;
    right: inherit;
    background: white;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    z-index: 9999;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    border-radius: 8px;


}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
    display: flex;
}
.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
/*.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}*/
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth:hover,
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.react-calendar__month-view__days__day--neighboringMonth:hover,
.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth:hover
{
    background-color: inherit !important;
    pointer-events: none;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
}
.react-calendar__tile:disabled {
    /*background-color: #f0f0f0;*/
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__tile--now {
    /*background: #212729;
    color: white;*/
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #1976d0;
    color: white;
}
/*.react-calendar__tile--hasActive {
    background: #212729;
    color: white;
}*/
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #1976d0;
}
.react-calendar__tile--active {
    background: #1976d029;
    color: black;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1976d0;
}
.react-calendar--selectRange .react-calendar__tile--hover:not( .react-calendar__month-view__days__day--neighboringMonth ) {
    background-color: #e6e6e6;
}
.react-calendar__tile--rangeStart {
    background: #1976d0;
    color: white;
}
.react-calendar__tile--rangeEnd {
    background: #1976d0;
    color: white;
}
.react-calendar__tile--hoverEnd,
.react-calendar__tile--hoverStart {
    background: #1976d0 !important;
    color: white;
}
.react-calendar__month-view__days__day--neighboringMonth.react-calendar__tile--hoverEnd,
.react-calendar__month-view__days__day--neighboringMonth.react-calendar__tile--hoverStart
{
    background: inherit !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    background-color: white;
}

.react-calendar__month-view__days__day--neighboringMonth > abbr {
    display: none;
}

.react-calendar__month-view {
    border-right: 1px solid #dbdce1;
}

.react-calendar__navigation__label
{
    position: unset;
}

.react-calendar__navigation__label__labelText--from
{
    /*position: absolute;
    left: 20%;*/
    font-size: 17px;
}

.react-calendar__navigation__label__divider
{
    /*display: none;*/
    color: white;
    padding: 0 100px;
}

.react-calendar__navigation__label__labelText--to
{
    /*position: absolute;
    right: 20%;*/
    font-size: 17px;
}

.react-calendar__month-view__days__day--neighboringMonth:hover
{
    background-color: inherit;
}

/*react-calendar__tile react-calendar__tile--active react-calendar__tile--range react-calendar__tile--rangeStart react-calendar__tile--rangeEnd react-calendar__tile--rangeBothEnds react-calendar__month-view__days__day react-calendar__month-view__days__day--neighboringMonth*/
/*.react-calendar__tile.react-calendar__month-view__days__day react-calendar__month-view__days__day--neighboringMonth*/

.mobile-roundtrip-datepicker .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    flex-direction: column;
}
.mobile-roundtrip-datepicker .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: auto;
}